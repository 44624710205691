import MenuChild from "./Child.vue"
import MenuChildCreate from "./Create.vue"
import MenuChildEdit from "./Edit.vue"


export default [
    {
        path: "/menu_child",
        component: MenuChild,
        name: "MenuChild"
    },
    {
        path: "/menu_child/create",
        component: MenuChildCreate,
        name: "MenuChildCreate"
    },
    {
        path: "/menu_child/edit/:id",
        component: MenuChildEdit,
        name: "MenuChildEdit"
    },
]