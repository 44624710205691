<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table>
        <h1>{{ name }}</h1>
      </template>
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Menu</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <h2 class="section-title">Forms</h2>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form @submit.prevent>
              <div class="card-body">
                <div class="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.name"
                  />
                  <small class="text-danger" v-if="errors.name">{{
                    errors.name[0]
                  }}</small>
                </div>
                <div class="form-group">
                  <label>Icon</label>
                  <input
                    type="text"
                    v-model="form.icon"
                    class="form-control"
                  />
                </div>
                <small class="text-danger" v-if="errors.icon">{{
                  errors.icon[0]
                }}</small>
                <div class="form-group">
                  <label>Path</label>
                  <input
                    type="text"
                    v-model="form.path"
                    class="form-control"
                  />
                </div>
                <small class="text-danger" v-if="errors.path">{{
                  errors.path[0]
                }}</small>
                <div class="form-group">
                  <label>Component</label>
                  <input
                    type="text"
                    v-model="form.component"
                    class="form-control"
                  />
                </div>
                <small class="text-danger" v-if="errors.component">{{
                  errors.component[0]
                }}</small>
              </div>
              <div class="card-footer text-right">
                <button
                  class="btn btn-primary mr-1"
                  type="submit"
                  @click="menuCreate"
                >
                  Submit
                </button>
                <button class="btn btn-secondary" type="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
export default {
  name: "MenuChildCreate",
  data() {
    return {
      name: "Create Submenu",
      form: {
        menu_id: this.$route.query.menu_id,
        name: "",
        path: "",
        component: "",
        icon: "",
      },
      errors: {},
    };
  },
  beforeMount() {},
  methods: {
    menuCreate() {
      axios
        .post(this.$store.state.api + "menu_child/store", this.form, {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then(() => {
          this.$noty.success("Your submenu has been created!");
          this.$router.back();
        })
        .catch((error) => {
          this.errors = error.response.data;
        });
    },
  },
};
</script>
