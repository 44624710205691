var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section"},[_c('base-header',{scopedSlots:_vm._u([{key:"header-table",fn:function(){return [_c('h1',[_vm._v(_vm._s(_vm.name)+" "+_vm._s(_vm.main_menu.name))])]},proxy:true},{key:"item-slot",fn:function(){return [_c('div',{staticClass:"breadcrumb-item"},[_vm._v(_vm._s(_vm.name)+" "+_vm._s(_vm.main_menu.name))])]},proxy:true}])}),_c('section',{staticClass:"body"},[_c('h2',{staticClass:"section-title"},[_vm._v(_vm._s(_vm.name)+" "+_vm._s(_vm.main_menu.name))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',[_vm._v(_vm._s(_vm.name)+" "+_vm._s(_vm.main_menu.name)+" Table")]),_c('div',{staticClass:"card-header-form"},[_c('div',{staticClass:"input-group"},[_c('router-link',{staticClass:"btn btn-primary mr-1",attrs:{"to":{
                    name: 'MenuChildCreate',
                    query: { menu_id: this.$route.query.menu_id },
                  }}},[_vm._v("Create")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Search","label":"Search"},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}}),_vm._m(0)],1)])]),_c('div',{staticClass:"card-body"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.menu,"item-key":"id","search":_vm.search,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(row){return [_c('tr',[_c('td',[_vm._v(_vm._s(row.item.name))]),_c('td',[_vm._v(_vm._s(row.item.path))]),_c('td',[_vm._v(_vm._s(row.item.component))]),_c('td',[_c('i',{class:row.item.icon})]),_c('td',{class:[
                      row.item.is_index == true
                        ? 'text-success'
                        : 'text-danger',
                    ]},[_vm._v(" "+_vm._s(row.item.is_index)+" ")]),_c('td',[_c('button',{staticClass:"badge badge-primary mr-1",on:{"click":function($event){return _vm.duplicateData(row.item)}}},[_vm._v(" Duplicate ")]),_c('router-link',{staticClass:"badge badge-warning mr-1",attrs:{"to":{
                        name: 'MenuChildEdit',
                        params: { id: row.item.id },
                      }}},[_vm._v("Edit")]),_c('button',{staticClass:"badge badge-danger",on:{"click":function($event){return _vm.deleteData(row.item.id)}}},[_vm._v(" Delete ")])],1)])]}}])})],1)])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-group-btn"},[_c('button',{staticClass:"btn btn-primary"},[_c('i',{staticClass:"fas fa-search"})])])
}]

export { render, staticRenderFns }